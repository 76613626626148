import * as React from "react"
import styled from 'styled-components';

const ProjectCaseStudy = (props) => {
  return (
    <Grid>
      <div className="company-info">
        <span>{props.company}</span> {props.companyInfo}
      </div>

      <div className="background">
        <p className="background-title">{props.backgroundTitle}</p>
        <p className="background-text">{props.backgroundText}</p>
      </div>

      <div className="grid">
        <div className="problem">
          <p className="problem-title">{props.problemTitle}</p>
          <p className="problem-text">{props.problemText}</p>
        </div>
        <div className="outcome">
          <p className="outcome-title">{props.outcomeTitle}</p>
          <p className="outcome-text">{props.outcomeText}</p>
        </div>
      </div>

    </Grid>
  )
}

export default ProjectCaseStudy


const Grid = styled.div`
  p {margin: 0}

  .company-info {
    font-weight: 400;
    font-size: clamp( 16px,2vw,18px);
    line-height: 1.6;
    margin: 32px 0px;
    padding: 0px 0px 0px 11px;
    border-left: 3px solid var(--black-blue);
    color: var(--black-blue);
    span {
      font-weight: bold;
    }
    @media (max-width: 1200px) {
    margin-left: 48px;
    margin-right: 48px;
    }
    @media (max-width: 850px) {
      margin-left: 48px;
      margin-right: 48px;
    }
    @media (max-width: 650px) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .background {
    .background-title {
      font-weight: 600;
      font-size: clamp( 16px,2vw,18px);
      padding: 0px 0px 16px 0px;
      color: var(--black-blue);
    }
    .background-text {
      font-weight: 400;
      font-size: clamp( 16px,2vw,18px);
      line-height: 1.6;
      color: var(--black-blue);
    }
    @media (max-width: 1200px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 850px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 650px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
    margin: 32px 0px;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      grid-row-gap: 32px;
    }
    @media (max-width: 1200px) {
    padding-left: 48px;
    padding-right: 48px;
    }
    @media (max-width: 850px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 650px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    .problem-title {
      font-weight: 600;
      font-size: clamp( 16px,2vw,18px);
      padding: 0px 0px 16px 0px;
      color: var(--black-blue);
    }
    .problem-text {
      font-weight: 400;
      font-size: clamp( 16px,2vw,18px);
      line-height: 1.6;
      color: var(--black-blue);
    }

    .outcome-title {
      font-weight: 600;
      font-size: clamp( 16px,2vw,18px);
      padding: 0px 0px 16px 0px;
      color: var(--black-blue);
    }
    .outcome-text {
      font-weight: 400;
      font-size: clamp( 16px,2vw,18px);
      line-height: 1.6;
      color: var(--black-blue);
    }
  }
  

`