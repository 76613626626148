import * as React from "react"
import styled from 'styled-components';
import { ArrowUpRight } from 'react-feather';


const ProjectSubHeaderTwo = (props) => {
  return (
    <Grid>

        <h2 className="subheader__title">{props.title}</h2>

				<a href ={props.href2} target="_blank" className="subheader__button">
          {props.button2}
          <ArrowUpRight />
        </a>

				<a href ={props.href} target="_blank" className="subheader__button">
          {props.button}
          <ArrowUpRight />
        </a>
			
    </Grid>
  )
}

export default ProjectSubHeaderTwo


const Grid = styled.div`
  display: grid;
	grid-template-columns: 1fr .2fr .2fr;
  border-bottom: 1px solid #C4C4C4;
	align-items: center;

  @media (max-width: 680px) {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr .5fr .5fr;
  }

  .subheader__title {
    padding-bottom: 16px;
    font-weight: 900;
    font-size: clamp(100%, 36px + 2vw, 48px);
    color: var(--black-blue);
  }
  .subheader__button {
    background: #fff;
    border-radius: 6px;
    border: solid 2px var(--black-blue);
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    color: var(--black-blue);
    text-align: center;
    margin-bottom: 16px;
    text-decoration: none;
    display: flex;
		justify-content: space-between;
		white-space: nowrap;

		margin-left: 16px;

    transition: transform 200ms,background 200ms;
    transition-property: transform, background;
    transition-duration: 200ms, 200ms;
    transition-timing-function: ease, ease;
    transition-delay: 0s, 0s;

    :hover {
      transform: translateY(-2px);
    }

		@media (max-width: 680px) {
			max-width: none;
			margin-left: 0px;
  	}
  }
  @media (max-width: 1200px) {
    margin-left: 48px;
    margin-right: 48px;
  }
  @media (max-width: 850px) {
    margin-left: 48px;
    margin-right: 48px;
  }
  @media (max-width: 650px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`
