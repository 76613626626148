import * as React from "react"
import styled from 'styled-components';
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

//Components
import ProjectInfo from '../../components/ProjectInfo';
import ProjectHeader from '../../components/ProjectHeader';
import ProjectCaseStudy from '../../components/ProjectCaseStudy';
import ProjectSubHeaderTwo from "../../components/ProjectSubHeaderTwo";
import FooterWithPadding from '../../components/FooterWithPadding';

const BootstrapDesign = () => {
  const data = useStaticQuery(graphql`
  query {

    bootstrapOne: file(relativePath: { eq: "bootstrap-design/bs-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1261) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bootstrapTwo: file(relativePath: { eq: "bootstrap-design/bs-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1261) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bootstrapThree: file(relativePath: { eq: "bootstrap-design/bs-3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1261) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
  }
`)

return (
    <Grid>
      <ProjectHeader 
        title="Bootstrap Design"
        subTitle="The missing design companion for Bootstrap 4"
      />
      <ProjectInfo 
        when="2020"
        platform="Figma"
        symbolPlatform="🎨"
        team="Me"
        role="Creator, Designer, Developer"
        tools="Figma, Gatsby JS, React, Styled Components, Google Analytics, Notion."
      />


      <ProjectCaseStudy 
        backgroundTitle="Background"
        backgroundText="
        Bootstrap Design is a Figma design kit based on the popular front-end framework, Bootstrap.
        The origin of this framework is that I found myself repeatedly working on projects 
        that used Bootstrap. Whether I liked that or not, it proved to me that even in the age 
        of Jamstack, it still had relevancy and that I needed a design equivalent. 
        After exploring the market for a decent Figma kit, my search came up short. Thus, 
        began the process of designing and building this framework.
        "

        problemTitle="Problems With Existing Solutions"
        problemText="Kits made with Sketch existed, but I felt they always came up short. 
        They failed to bring the vast amount of kitchen sink components that the Bootstrap framework offers. 
        They also had no duality with Bootstrap, with entirely different naming conventions and organization 
        than what's found in the documentation. So, with these discoveries in mind, the purpose of this project was born: 
        create a Bootstrap design system counterpart for Figma. One that is relatively affordable, and at its 
        base, gives you everything covered in the actual Bootstrap framework."

        outcomeTitle="Launch Results"
        outcomeText="
        Launched in 2020 via Product Hunt, Bootstrap Design was a hit from day one. It landed at the #3 product
        of the day, giving it a lot of exposure. Its sales numbers are in the hundreds, and the feedback from 
        designers has been great. It is very popular with agencies and has some notable designers using it at 
        companies like Warner Media, Clear Banc, and Standford. Getting praise and shoutouts from designers 
        who used the framework is truly something special. It's great to know that hundreds of creators 
        are saving time when creating a design system around Bootstrap."
      />

      <ProjectSubHeaderTwo
        title="Feature Showcase"
        button="View Landing Page"
        href="https://getbootstrap.design/"
        button2="Figma Preview"
        href2="https://www.figma.com/file/q5xqCqlwRC9KxfFsIeWMuR/Bootstrap-Design-1.2-(Preview)?node-id=0%3A1"
      />

      <ProjectShowcaseCard>
        <Images fluid={data.bootstrapOne.childImageSharp.fluid} />
      </ProjectShowcaseCard>
      <ProjectShowcaseCard>
        <Images fluid={data.bootstrapTwo.childImageSharp.fluid} />
      </ProjectShowcaseCard>
      <ProjectShowcaseCard>
        <Images fluid={data.bootstrapThree.childImageSharp.fluid} />
      </ProjectShowcaseCard>
      
    <FooterWithPadding />
    </Grid>
  )
}

export default BootstrapDesign




const Grid = styled.div`
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  max-width: 1110px;
  margin: 0 auto;
`

const ProjectShowcaseCard = styled.div`
  /* background: #F6F6F6;
  border-radius: 1rem; */
  padding: 48px;
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 64px;

  border: .1rem solid #c7c7cc;
  box-shadow: 0 0.2rem 0.6rem 0 rgba(37,37,37,0.1);
  border-radius: 1rem;
  background: rgba(221, 221, 221, 0.11);

  @media (max-width: 900px) {
    padding: 24px;
  }
  @media (max-width: 1200px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`

const Images = styled(Img)`

`